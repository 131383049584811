:root {
  --jelly-bean: #286aa6;
  --black-squeeze: #dfe9f3;
  --athens-gray: #e1e4e9;
  --snuff: #ece2f0;
  --geyser: #dde4e8;
  --allports: #006ab0;
  --jelly-tuna: #2d333f;
  --steel-blue: #3d79af;
  --violet: #a6288a;
  --daisy-bush: #4e28a6;
  --allports: #006aab;
  --jungle-green: #26b564;
  --gable-green: #143938;
  --tuna: #3d3d42;
  --link-water: #f4f8fc;
  --white: #fff;
  --black: #000;
  --alabaster: #f7f7f7;
  --red: #f14a44;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--jelly-bean);
  min-width: 320px;
}

p {
  margin: 0;
  margin-bottom: 34px;
  line-height: 33px;
  font-size: 20px;
  color: var(--tuna);
  letter-spacing: 0;
}
