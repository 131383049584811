.root {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  padding: 45px 20px 20px 50px;
}

.vacancy {
  max-width: 1200px;
  margin: 0 50px 0 100px;
  overflow: auto;
}

.title {
  font-size: 39px;
  color: var(--tuna);
}

.salary {
  font-weight: 600;
  font-size: 20px;
}

.head {
  padding: 18px 20px 44px;
}

.description {
  padding: 20px;
  font-size: 2.2vh;
  line-height: 4vh;
}

.button {
  margin-left: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

:global(.vacancy-description) {
  margin-top: -40px;
  font-size: 20px;
  line-height: 30px;
}

:global(.experience) {
  margin-bottom: 10px;
}

:global(.expectations) {
  margin-bottom: 20px;
}

:global(.employment) {
  margin-bottom: 10px;
}

:global(.requirements) {
  padding-left: 25px;
}

.link {
  background-color: var(--jelly-bean);
  color: var(--white);
  text-transform: uppercase;
  text-decoration: none;
  padding: 12px 14px;
  display: inline-block;
  font-size: 12px;
  border-radius: 8px;
}

@media screen and (max-width: 1024px) {
  .vacancy {
    margin: 0;
  }
  .head,
  .vacancy {
    border: 0;
  }
}
