.technologies {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: -4vh;
}

.image {
  max-width: 100%;
}

.header {
  font-size: 40px;
  font-weight: 900;
  line-height: 73px;
  color: var(--tuna);
  letter-spacing: 1px;
}

.list li {
  margin-bottom: 15px;
  padding-left: 7px;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.picture {
  position: relative;
}

@media screen and (min-width: 768px) {
  .block {
    width: 50%;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .technologies {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  .media,
  .content {
    margin-top: -6vh;
    width: 50%;
  }

  .title {
    font-weight: 600;
    font-size: 2.7vh;
    margin-bottom: 0.5vh;
  }
  .list li {
    margin-bottom: 2vh;
    padding-left: 7px;
    font-size: 2.1vh;
    line-height: 3.7vh;
    margin-bottom: 0;
  }

  .reverse {
    flex-direction: column-reverse;
  }
  .image {
    max-height: 76vh;
    display: block;
    margin: 0 auto;
  }
  .technologies {
    margin-left: -1vh;
  }
  .picture {
    max-width: 622px;
    left: -33px;
    top: 105px;
  }

  .header,
  .technologies {
    padding-left: 8vh;
  }

  .header {
    margin-top: 16.7vh;
    margin-bottom: 5.3vh;
    margin-left: -1vh;
    font-size: 6.6vh;
    line-height: 7vh;
  }
}
