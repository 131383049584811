:global(.ReactModal__Overlay) {
  z-index: 10;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  display: flex;
  justify-content: center;
}

:global(.ReactModal__Content) {
  transition: all 500ms ease-in-out;
  max-width: 1370px;
  transform: translate3d(0, 150px, 0);
}

:global(.ReactModal__Overlay--after-open) {
  opacity: 1;
}

:global(.ReactModal__Overlay--before-close) {
  opacity: 0;
}

:global(.ReactModal__Content--after-open) {
  transform: translate3d(0, 0, 0);
}

:global(.ReactModal__Content--before-close) {
  transform: translate3d(0, 150px, 0);
}

.modal {
  top: 85px;
  right: auto;
  left: auto;
  bottom: 0px;
  height: calc(100% - 85px);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: rgb(244, 248, 252);
  position: absolute;
}

.closeButton {
  background: transparent;
  border: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.closeIcon {
  width: 20px;
  height: 20px;
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}
