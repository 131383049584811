.menuOpened main:before {
  content: '';
  background-color: #000;
  position: fixed;
  z-index: 15;
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

main > section {
  padding: 58px 0 0 0;
}

.root {
  overflow: hidden;
}
