.features {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.header {
  font-size: 40px;
  font-weight: 900;
  color: var(--tuna);
  letter-spacing: 1px;
}

.picture {
  position: relative;
  left: 0;
  top: 7.5vh;
  margin-bottom: 80px;
}

.desktopDots {
  max-width: 69vw;
}

.image {
  position: absolute;
  width: 77vw;
  max-width: 463px;
  left: 9vw;
  top: 9vw;
}

.item {
  min-width: 50%;
  margin-bottom: 70px;
}

.icon {
  min-height: 60px;
  display: flex;
  margin-bottom: 20px;
  align-items: flex-end;
  justify-content: center;
}

.text {
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.paragraph {
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 20px;
  word-break: break-word;
}

@media screen and (min-width: 768px) {
  .image {
    left: 50px;
    top: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .media,
  .content {
    width: 50%;
    margin-top: 6vh;
  }
  .desktopDots {
    max-height: 62vh;
    max-width: none;
  }
  .picture {
    margin-bottom: 0;
    left: -5.7vh;
  }
  .item {
    min-width: 100px;
    margin-bottom: 0;
  }

  .paragraph {
    font-size: 2vh;
    line-height: 3.5vh;
    margin-bottom: 2.5vh;
    max-width: 95%;
  }
  .text {
    font-size: 2vh;
  }

  .header {
    margin-top: 10.7vh;
    margin-bottom: 4vh;
    font-size: 6.6vh;
    line-height: 7vh;
  }

  .image {
    max-height: 48.5vh;
    max-width: 480px;
    width: auto;
    left: 5.2vh;
    top: 4.9vh;
  }
}

@media screen and (min-width: 1280px) {
  .text {
    font-size: 1.8vh;
  }
}
