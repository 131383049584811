.root {
  min-height: 35px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0);
}

.resume {
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 8px;
  background-color: var(--jungle-green);
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.04px;
  text-align: center;
  padding: 12px 14px;
  border: none;
  outline: none;
  transition: 0.35s;
}

.navContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo {
  width: 105px;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container {
  margin: auto;
  box-sizing: border-box;
  padding: 15px 40px;
  transition: background 0.2s ease-in 0s;
}

.white {
  color: var(--white);
}

.blue {
  color: var(--jelly-bean);
}

.dark {
  color: var(--jelly-tuna);
}

.pink {
  color: var(--violet);
}

.purple {
  color: var(--daisy-bush);
}

.red {
  color: var(--red);
}

.darkGreen {
  color: var(--gable-green);
}

.nav ul {
  list-style-type: none;
  display: flex;
  margin: 0;
}

.nav ul li {
  margin-right: 18px;
  display: inline-block;
  font-size: 0;
}

.nav ul li:last-child {
  margin-right: 0;
}

.nav ul a {
  display: inline-block;
  color: inherit;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 1s ease 0s;
  vertical-align: top;
  line-height: 19px;
}

.nav ul a.hidden {
  display: none;
}

.nav ul a:hover {
  font-weight: 600;
}

.nav ul a::after {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.button {
  border-radius: 8px;
  background-color: var(--jungle-green);
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 16px;
  text-align: center;
  padding: 12px 14px;
  border: none;
}

.resume,
.nav {
  display: none;
}

.hamburger {
  border: none;
  background-color: transparent;
}

.burger {
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  color: inherit;
  border: 0;
  background-color: transparent;
  outline: none;
  background: var(--steel-blue);
  border-radius: 50%;
  transition: background-color 0.3s ease-in 0s;
}

.blue .burger {
  background: var(--black-squeeze);
}

.dark .burger {
  background: var(--athens-gray);
}

.pink .burger {
  background: var(--snuff);
}

.darkGreen .burger {
  background: var(--geyser);
}

.purple .burger {
  background: var(--snuff);
}

.red .burger {
  background: var(--red);
}

.burgerInner {
  position: absolute;
  width: 25px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--white);
  left: 0;
  transition: background-color 0.3s ease-in 0s;
}

.burgerBox {
  position: relative;
  display: inline-block;
  width: 25px;
  top: -6px;
}

.burgerInner:before,
.burgerInner:after {
  display: block;
  content: '';
  position: absolute;
  width: 25px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--white);
  transition: background-color 0.3s ease-in 0s;
}

.burgerInner:before {
  top: -8px;
}

.burgerInner:after {
  top: 8px;
}

.blue .burgerInner,
.blue .burgerInner:before,
.blue .burgerInner:after {
  background: var(--jelly-bean);
}

.dark .burgerInner,
.dark .burgerInner:before,
.dark .burgerInner:after {
  background: var(--jelly-tuna);
}

.pink .burgerInner,
.pink .burgerInner:before,
.pink .burgerInner:after {
  background: var(--violet);
}

.darkGreen .burgerInner,
.darkGreen .burgerInner:before,
.darkGreen .burgerInner:after {
  background: var(--gable-green);
}

.purple .burgerInner,
.purple .burgerInner:before,
.purple .burgerInner:after {
  background: var(--daisy-bush);
}

@media screen and (min-width: 1024px) {
  .resume,
  .nav {
    display: block;
  }

  .resume {
    padding: 0;
    margin-left: 29px;
    padding: 12px 16px;
    font-size: 11px;
  }

  .linkText {
    position: relative;
    top: 1px;
  }

  .container {
    padding: 16px 40px 17px;
    box-sizing: border-box;
    margin: auto;
  }

  .inner {
    max-width: 1440px;
    display: flex;
    margin: 0 auto;
  }

  .burger {
    display: none;
  }

  .blue,
  .dark,
  .pink,
  .darkGreen,
  .purple {
    background: rgba(244, 248, 252, 0.7);
  }
}

.current {
  font-weight: 600;
}
