.root {
  background-image: url('./bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 101vh;
  box-sizing: border-box;
  background-position: center;
}

.container {
  max-width: 1440px;
  margin: auto;
  display: flex;
  align-items: center;
}

:global(#root) .header {
  font-weight: 800;
  letter-spacing: 0.08px;
  line-height: 104px;
  text-align: center;
  margin: 0;
  width: 100%;
}

.greeting {
  color: var(--white);
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 0.08px;
  transform: translate(35px, 40vh);
  width: 100%;
}

.companyName {
  font-size: 25px;
}

@media screen and (min-width: 768px) {
  .greeting {
    font-size: 40px;
    line-height: 50px;
    transform: translate(135px, 40vh);
  }
}
@media screen and (min-width: 1024px) {
  .greeting {
    font-size: 80px;
    line-height: 104px;
  }

  .companyName {
    font-size: 25px;
    font-size: 65px;
  }

  :global(#root) .header {
    letter-spacing: 0.08px;
    line-height: 104px;
    text-align: left;
    margin-left: 42px;
    max-width: 760px;
  }
}
