h1.root {
  color: var(--tuna);
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 1.35px;
  line-height: 48px;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 0;
}

h2.root {
  color: var(--tuna);
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  margin-top: 0;
}

.root.white {
  color: var(--white);
}

@media screen and (min-width: 1024px) {
  h1.root {
    color: var(--tuna);
    font-size: 6vh;
    line-height: 9vh;
    font-weight: 900;
  }

  h2.root {
    font-size: 3.5vh;
    line-height: 6vh;
  }
}

@media screen and (min-width: 1280px) {
  h1.root {
    font-size: 6.6vh;
    margin-bottom: 18px;
  }
  h2.root {
    font-size: 2.7vh;
    line-height: 4.5vh;
  }
}
