.base {
  position: relative;
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.2, 1);
}

.fadeEnter {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.fromBottom {
  transform: translate3d(0, 50px, 0);
}

.fromTop {
  transform: translate3d(0, -50px, 0);
}

.fromLeft {
  transform: translate3d(-50px, 0, 0);
}

.fromRight {
  transform: translate3d(50px, 0, 0);
}

.longDuration {
  transition-duration: 1s;
}

.usualDuration {
  transition-duration: 0.5s;
}

.shortDuration {
  transition-duration: 0.3s;
}

.longestDelay {
  transition-delay: 1.2s;
}

.longDelay {
  transition-delay: 1s;
}

.averageDelay {
  transition-delay: 0.8s;
}

.usualDelay {
  transition-delay: 0.6s;
}

.shortDelay {
  transition-delay: 0.4s;
}

.shortestDelay {
  transition-delay: 0.2s;
}
