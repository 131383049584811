.root {
  min-height: 100vh;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80% 200px;
  overflow: hidden;
  background-color: var(--allports);
}

:global(#root) .header {
  color: var(--allports);
  margin-bottom: 17px;
}

.container {
  margin: auto;
  padding: 0 20px;
  margin-top: 120px;
}

.info {
  background: var(--white);
  border-radius: 7px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  height: 244px;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
}

.dotsMobile {
  position: absolute;
  top: -19px;
  left: -7px;
  pointer-events: none;
}

.dotsDesktop {
  display: none;
  position: absolute;
  top: -15px;
  left: -15px;
  pointer-events: none;
}

.item {
  display: flex;
  align-items: center;
  color: var(--allports);
  margin-bottom: 22px;
  font-size: 15px;
}

.item img {
  margin-right: 15px;
}

.phoneNumber,
.mailLink {
  text-decoration: none;
  color: var(--allports);
}

@media screen and (min-width: 1024px) {
  .root {
    background-position: -550px center;
  }
  .info {
    background: var(--white);
    border-radius: 7px;
    margin-top: 152px;
    margin-left: 62px;
    padding: 66px 40px 0 81px;
    box-sizing: border-box;
    height: 398px;
    max-width: 567px;
  }

  .container {
    max-width: 1539px;
    padding: 0;
    display: flex;
    margin-top: 0;
  }

  .item {
    font-size: 16px;
  }

  .item img {
    margin-right: 25px;
  }

  .dotsDesktop {
    display: block;
  }

  .dotsMobile {
    display: none;
  }

  :global(#root) .header {
    color: var(--allports);
    margin-bottom: 37px;
  }
}

@media screen and (min-width: 1280px) {
  .root {
    background-position: right top;
  }
}
