.root {
  background-color: var(--link-water);
  padding-top: 90px;
}

.media {
  order: 0;
  margin-bottom: 40px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.content {
  order: 1;
}

.desktopHeader {
  display: none;
}

.container {
  padding: 0 30px;
}

@media screen and (min-width: 1024px) {
  .root {
    height: 100vh;
    box-sizing: border-box;
  }

  .mobileHeader {
    display: none;
  }

  .desktopHeader {
    display: block;
  }

  .wrapper {
    flex-direction: row;
  }

  .contentWrapper {
    padding-right: 70px;
  }

  .container {
    max-width: 1100px;
    margin: auto;
    padding-top: 100px;
    padding: 0 30px;
  }

  .content,
  .media {
    flex-basis: 50%;
    width: 50%;
    overflow: hidden;
  }

  .media {
    margin-bottom: 0;
  }

  .root .content {
    order: 0;
  }

  .media {
    order: 1;
    display: flex;
    justify-content: center;
  }

  .reverse .content {
    order: 1;
  }

  .reverse .media {
    order: 0;
  }

  .reverse .contentWrapper {
    margin-left: 70px;
    padding-right: 0;
  }

  .root ul {
    margin: 0;
    padding: 0;
    padding-left: 2.5vh;
    margin-bottom: 1vh;
  }
}
