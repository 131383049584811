.primaryHeader {
  font-size: 40px;
  font-weight: 900;
  line-height: 73px;
  color: var(--tuna);
  letter-spacing: 1px;
}

.paragraph {
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 20px;
  word-break: break-word;
}

.learn {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 8px;
  border: solid 1px var(--red);
  color: var(--tuna);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.04px;
  text-align: center;
  padding: 12px 14px;
  outline: none;
  transition: 0.35s;
}

.linkText {
  position: relative;
  top: 1px;
}

.media {
  position: relative;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
}

.content {
  order: 1;
}

.media {
  order: 0;
}

.desktopDots {
  display: none;
  pointer-events: none;
  right: 90px;
}

.mobileDots {
  position: absolute;
  pointer-events: none;
  left: 50%;
  margin-left: -80px;
}

.image {
  position: relative;
  z-index: 1;
  width: 90vw;
  max-width: 483px;
  margin-left: -15px;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .image {
    left: 50px;
    top: 50px;
  }

  .media {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1024px) {
  .primaryHeader {
    margin-top: 11vh;
    margin-bottom: 4vh;
    font-size: 6.6vh;
    line-height: 7vh;
  }

  .paragraph {
    font-size: 2vh;
    line-height: 3.5vh;
    margin-bottom: 1.5vh;
    margin-right: 60px;
    max-width: 95%;
  }

  .learn {
    padding: 0;
    margin-top: 29px;
    padding: 12px 16px;
    font-size: 11px;
  }

  .content,
  .media {
    margin-top: 6vh;
  }
  .content {
    order: 0;
  }
  .media {
    order: 1;
    top: 7vh;
    left: 2vh;
    margin-bottom: 80px;
  }
  .content,
  .media {
    width: 50%;
  }
  .media {
    height: auto;
    margin-bottom: 0;
  }

  .mobileDots {
    display: none;
  }

  .desktopDots {
    display: block;
    position: absolute;
    top: -50px;
  }

  .image {
    max-width: 110%;
    width: auto;
    left: -60px;
    top: -10px;
  }
}
