.root {
  border-radius: 8px;
  background-color: var(--jungle-green);
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 16px;
  text-align: center;
  padding: 12px 14px;
  border: none;
  outline: none;
  transition: 0.35s;
}

.root:hover {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05), 0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.root.primary {
  background-color: transparent;
  border: none;
  color: var(--jelly-bean);
  border: 1px solid var(--jelly-bean);
  font-size: 18px;
}

.root.info {
  background-color: var(--jelly-bean);
  color: var(--white);
}

.root:hover {
  cursor: pointer;
}
