:root {
  --jelly-bean: #286aa6;
  --black-squeeze: #dfe9f3;
  --athens-gray: #e1e4e9;
  --snuff: #ece2f0;
  --geyser: #dde4e8;
  --allports: #006ab0;
  --jelly-tuna: #2d333f;
  --steel-blue: #3d79af;
  --violet: #a6288a;
  --daisy-bush: #4e28a6;
  --allports: #006aab;
  --jungle-green: #26b564;
  --gable-green: #143938;
  --tuna: #3d3d42;
  --link-water: #f4f8fc;
  --white: #fff;
  --black: #000;
  --alabaster: #f7f7f7;
  --red: #f14a44;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #286aa6;
  background-color: var(--jelly-bean);
  min-width: 320px;
}

p {
  margin: 0;
  margin-bottom: 34px;
  line-height: 33px;
  font-size: 20px;
  color: #3d3d42;
  color: var(--tuna);
  letter-spacing: 0;
}

.Appear_base__1rlZo {
  position: relative;
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.2, 1);
}

.Appear_fadeEnter__UJbPz {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.Appear_fromBottom__1o8wP {
  -webkit-transform: translate3d(0, 50px, 0);
          transform: translate3d(0, 50px, 0);
}

.Appear_fromTop__mkCJa {
  -webkit-transform: translate3d(0, -50px, 0);
          transform: translate3d(0, -50px, 0);
}

.Appear_fromLeft__2gS_V {
  -webkit-transform: translate3d(-50px, 0, 0);
          transform: translate3d(-50px, 0, 0);
}

.Appear_fromRight__1oIrJ {
  -webkit-transform: translate3d(50px, 0, 0);
          transform: translate3d(50px, 0, 0);
}

.Appear_longDuration__ISg91 {
  transition-duration: 1s;
}

.Appear_usualDuration__3nNnK {
  transition-duration: 0.5s;
}

.Appear_shortDuration__1QrWu {
  transition-duration: 0.3s;
}

.Appear_longestDelay__29uJB {
  transition-delay: 1.2s;
}

.Appear_longDelay__2E8gA {
  transition-delay: 1s;
}

.Appear_averageDelay__3DWsa {
  transition-delay: 0.8s;
}

.Appear_usualDelay__DTLqN {
  transition-delay: 0.6s;
}

.Appear_shortDelay__8vo6- {
  transition-delay: 0.4s;
}

.Appear_shortestDelay__1E5uv {
  transition-delay: 0.2s;
}

.Header_root__3h4Aa {
  min-height: 35px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0);
}

.Header_resume__1MiZG {
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 8px;
  background-color: var(--jungle-green);
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.04px;
  text-align: center;
  padding: 12px 14px;
  border: none;
  outline: none;
  transition: 0.35s;
}

.Header_navContainer__2szFk {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Header_logo__3Ww4H {
  width: 105px;
}

.Header_inner__fe5JN {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header_container__2jZ4P {
  margin: auto;
  box-sizing: border-box;
  padding: 15px 40px;
  transition: background 0.2s ease-in 0s;
}

.Header_white__3TwH1 {
  color: var(--white);
}

.Header_blue__1KDd7 {
  color: var(--jelly-bean);
}

.Header_dark__qrWTI {
  color: var(--jelly-tuna);
}

.Header_pink__2vYDw {
  color: var(--violet);
}

.Header_purple__2KfTp {
  color: var(--daisy-bush);
}

.Header_red__14xab {
  color: var(--red);
}

.Header_darkGreen__22Fkt {
  color: var(--gable-green);
}

.Header_nav__3RIrU ul {
  list-style-type: none;
  display: flex;
  margin: 0;
}

.Header_nav__3RIrU ul li {
  margin-right: 18px;
  display: inline-block;
  font-size: 0;
}

.Header_nav__3RIrU ul li:last-child {
  margin-right: 0;
}

.Header_nav__3RIrU ul a {
  display: inline-block;
  color: inherit;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 1s ease 0s;
  vertical-align: top;
  line-height: 19px;
}

.Header_nav__3RIrU ul a.Header_hidden__3GJJq {
  display: none;
}

.Header_nav__3RIrU ul a:hover {
  font-weight: 600;
}

.Header_nav__3RIrU ul a::after {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.Header_button__3HD3C {
  border-radius: 8px;
  background-color: var(--jungle-green);
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 16px;
  text-align: center;
  padding: 12px 14px;
  border: none;
}

.Header_resume__1MiZG,
.Header_nav__3RIrU {
  display: none;
}

.Header_hamburger__3byTi {
  border: none;
  background-color: transparent;
}

.Header_burger__uNEJR {
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  color: inherit;
  border: 0;
  background-color: transparent;
  outline: none;
  background: var(--steel-blue);
  border-radius: 50%;
  transition: background-color 0.3s ease-in 0s;
}

.Header_blue__1KDd7 .Header_burger__uNEJR {
  background: var(--black-squeeze);
}

.Header_dark__qrWTI .Header_burger__uNEJR {
  background: var(--athens-gray);
}

.Header_pink__2vYDw .Header_burger__uNEJR {
  background: var(--snuff);
}

.Header_darkGreen__22Fkt .Header_burger__uNEJR {
  background: var(--geyser);
}

.Header_purple__2KfTp .Header_burger__uNEJR {
  background: var(--snuff);
}

.Header_red__14xab .Header_burger__uNEJR {
  background: var(--red);
}

.Header_burgerInner__1oNLE {
  position: absolute;
  width: 25px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--white);
  left: 0;
  transition: background-color 0.3s ease-in 0s;
}

.Header_burgerBox__34oGv {
  position: relative;
  display: inline-block;
  width: 25px;
  top: -6px;
}

.Header_burgerInner__1oNLE:before,
.Header_burgerInner__1oNLE:after {
  display: block;
  content: '';
  position: absolute;
  width: 25px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--white);
  transition: background-color 0.3s ease-in 0s;
}

.Header_burgerInner__1oNLE:before {
  top: -8px;
}

.Header_burgerInner__1oNLE:after {
  top: 8px;
}

.Header_blue__1KDd7 .Header_burgerInner__1oNLE,
.Header_blue__1KDd7 .Header_burgerInner__1oNLE:before,
.Header_blue__1KDd7 .Header_burgerInner__1oNLE:after {
  background: var(--jelly-bean);
}

.Header_dark__qrWTI .Header_burgerInner__1oNLE,
.Header_dark__qrWTI .Header_burgerInner__1oNLE:before,
.Header_dark__qrWTI .Header_burgerInner__1oNLE:after {
  background: var(--jelly-tuna);
}

.Header_pink__2vYDw .Header_burgerInner__1oNLE,
.Header_pink__2vYDw .Header_burgerInner__1oNLE:before,
.Header_pink__2vYDw .Header_burgerInner__1oNLE:after {
  background: var(--violet);
}

.Header_darkGreen__22Fkt .Header_burgerInner__1oNLE,
.Header_darkGreen__22Fkt .Header_burgerInner__1oNLE:before,
.Header_darkGreen__22Fkt .Header_burgerInner__1oNLE:after {
  background: var(--gable-green);
}

.Header_purple__2KfTp .Header_burgerInner__1oNLE,
.Header_purple__2KfTp .Header_burgerInner__1oNLE:before,
.Header_purple__2KfTp .Header_burgerInner__1oNLE:after {
  background: var(--daisy-bush);
}

@media screen and (min-width: 1024px) {
  .Header_resume__1MiZG,
  .Header_nav__3RIrU {
    display: block;
  }

  .Header_resume__1MiZG {
    padding: 0;
    margin-left: 29px;
    padding: 12px 16px;
    font-size: 11px;
  }

  .Header_linkText__2f1JG {
    position: relative;
    top: 1px;
  }

  .Header_container__2jZ4P {
    padding: 16px 40px 17px;
    box-sizing: border-box;
    margin: auto;
  }

  .Header_inner__fe5JN {
    max-width: 1440px;
    display: flex;
    margin: 0 auto;
  }

  .Header_burger__uNEJR {
    display: none;
  }

  .Header_blue__1KDd7,
  .Header_dark__qrWTI,
  .Header_pink__2vYDw,
  .Header_darkGreen__22Fkt,
  .Header_purple__2KfTp {
    background: rgba(244, 248, 252, 0.7);
  }
}

.Header_current__D3KO- {
  font-weight: 600;
}

.Meet_root__orKCj {
  background-image: url(/static/media/bg.b94f91a7.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 101vh;
  box-sizing: border-box;
  background-position: center;
}

.Meet_container__1gcVl {
  max-width: 1440px;
  margin: auto;
  display: flex;
  align-items: center;
}

#root .Meet_header__2C3BB {
  font-weight: 800;
  letter-spacing: 0.08px;
  line-height: 104px;
  text-align: center;
  margin: 0;
  width: 100%;
}

.Meet_greeting__1D9qq {
  color: var(--white);
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 0.08px;
  -webkit-transform: translate(35px, 40vh);
          transform: translate(35px, 40vh);
  width: 100%;
}

.Meet_companyName__2s-iH {
  font-size: 25px;
}

@media screen and (min-width: 768px) {
  .Meet_greeting__1D9qq {
    font-size: 40px;
    line-height: 50px;
    -webkit-transform: translate(135px, 40vh);
            transform: translate(135px, 40vh);
  }
}
@media screen and (min-width: 1024px) {
  .Meet_greeting__1D9qq {
    font-size: 80px;
    line-height: 104px;
  }

  .Meet_companyName__2s-iH {
    font-size: 25px;
    font-size: 65px;
  }

  #root .Meet_header__2C3BB {
    letter-spacing: 0.08px;
    line-height: 104px;
    text-align: left;
    margin-left: 42px;
    max-width: 760px;
  }
}

.TwoSide_root__6x3sZ {
  background-color: var(--link-water);
  padding-top: 90px;
}

.TwoSide_media__zs6Dr {
  order: 0;
  margin-bottom: 40px;
}

.TwoSide_wrapper__20O_v {
  display: flex;
  flex-direction: column;
}

.TwoSide_content__1UM44 {
  order: 1;
}

.TwoSide_desktopHeader__2khMC {
  display: none;
}

.TwoSide_container__3UvYX {
  padding: 0 30px;
}

@media screen and (min-width: 1024px) {
  .TwoSide_root__6x3sZ {
    height: 100vh;
    box-sizing: border-box;
  }

  .TwoSide_mobileHeader__32biS {
    display: none;
  }

  .TwoSide_desktopHeader__2khMC {
    display: block;
  }

  .TwoSide_wrapper__20O_v {
    flex-direction: row;
  }

  .TwoSide_contentWrapper__1GOVY {
    padding-right: 70px;
  }

  .TwoSide_container__3UvYX {
    max-width: 1100px;
    margin: auto;
    padding-top: 100px;
    padding: 0 30px;
  }

  .TwoSide_content__1UM44,
  .TwoSide_media__zs6Dr {
    flex-basis: 50%;
    width: 50%;
    overflow: hidden;
  }

  .TwoSide_media__zs6Dr {
    margin-bottom: 0;
  }

  .TwoSide_root__6x3sZ .TwoSide_content__1UM44 {
    order: 0;
  }

  .TwoSide_media__zs6Dr {
    order: 1;
    display: flex;
    justify-content: center;
  }

  .TwoSide_reverse__2STNX .TwoSide_content__1UM44 {
    order: 1;
  }

  .TwoSide_reverse__2STNX .TwoSide_media__zs6Dr {
    order: 0;
  }

  .TwoSide_reverse__2STNX .TwoSide_contentWrapper__1GOVY {
    margin-left: 70px;
    padding-right: 0;
  }

  .TwoSide_root__6x3sZ ul {
    margin: 0;
    padding: 0;
    padding-left: 2.5vh;
    margin-bottom: 1vh;
  }
}

.About_meetText__3UZxA {
  margin-bottom: 39px;
}

.About_paragraph__1vKhk {
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 20px;
  word-break: break-word;
}

.About_primaryHeader__3QCQ3 {
  font-size: 40px;
  font-weight: 900;
  line-height: 73px;
  color: var(--tuna);
  letter-spacing: 1px;
}

.About_secondaryHeader__2SEAo {
  font-size: 24px;
  font-weight: 600;
  line-height: 39px;
  color: var(--tuna);
}

.About_media__1Jnwq {
  position: relative;
  left: 2vh;
  margin-bottom: 60px;
}

.About_content__1pSxm {
  order: 1;
}

.About_media__1Jnwq {
  order: 0;
}

.About_desktopDots__hh5w2 {
  position: absolute;
  max-width: 75vw;
}

.About_image__aRKiT {
  position: relative;
  z-index: 1;
  width: 68vw;
  max-width: 483px;
  top: 7vw;
  left: 7vw;
}

@media screen and (min-width: 768px) {
  .About_image__aRKiT {
    left: 50px;
    top: 50px;
  }
  .About_media__1Jnwq {
    margin-bottom: 120px;
  }
}
@media screen and (min-width: 1024px) {
  .About_secondaryHeader__2SEAo {
    font-size: 2.8vh;
    line-height: 4.4vh;
  }

  .About_content__1pSxm,
  .About_media__1Jnwq {
    margin-top: 6vh;
  }
  .About_content__1pSxm {
    order: 0;
  }
  .About_media__1Jnwq {
    order: 1;
    top: 7vh;
    margin-bottom: 80px;
  }
  .About_desktopDots__hh5w2 {
    position: absolute;
    max-height: 60vh;
    max-width: none;
  }
  .About_image__aRKiT {
    max-width: 100%;
    top: 5.5vh;
    left: 5.5vh;
    max-height: 49vh;
    width: auto;
  }
  .About_primaryHeader__3QCQ3 {
    margin-top: 11vh;
    margin-bottom: 4vh;
    font-size: 6.6vh;
    line-height: 7vh;
  }
  .About_secondaryHeader__2SEAo {
    margin-top: 5.5vh;
    margin-bottom: 0;
  }
  .About_paragraph__1vKhk {
    font-size: 2vh;
    line-height: 3.5vh;
    margin-bottom: 1.5vh;
    max-width: 95%;
  }
  .About_content__1pSxm,
  .About_media__1Jnwq {
    width: 50%;
  }
  .About_media__1Jnwq {
    height: auto;
    margin-bottom: 0;
  }
}

.Activity_features__35ICu {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.Activity_header__32xRr {
  font-size: 40px;
  font-weight: 900;
  color: var(--tuna);
  letter-spacing: 1px;
}

.Activity_picture__7cE7s {
  position: relative;
  left: 0;
  top: 7.5vh;
  margin-bottom: 80px;
}

.Activity_desktopDots__3RGci {
  max-width: 69vw;
}

.Activity_image__2RLU1 {
  position: absolute;
  width: 77vw;
  max-width: 463px;
  left: 9vw;
  top: 9vw;
}

.Activity_item__1x_nW {
  min-width: 50%;
  margin-bottom: 70px;
}

.Activity_icon__1WvIM {
  min-height: 60px;
  display: flex;
  margin-bottom: 20px;
  align-items: flex-end;
  justify-content: center;
}

.Activity_text__2Pmnr {
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.Activity_paragraph__1Imj0 {
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 20px;
  word-break: break-word;
}

@media screen and (min-width: 768px) {
  .Activity_image__2RLU1 {
    left: 50px;
    top: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .Activity_media__9UGkH,
  .Activity_content__14y2B {
    width: 50%;
    margin-top: 6vh;
  }
  .Activity_desktopDots__3RGci {
    max-height: 62vh;
    max-width: none;
  }
  .Activity_picture__7cE7s {
    margin-bottom: 0;
    left: -5.7vh;
  }
  .Activity_item__1x_nW {
    min-width: 100px;
    margin-bottom: 0;
  }

  .Activity_paragraph__1Imj0 {
    font-size: 2vh;
    line-height: 3.5vh;
    margin-bottom: 2.5vh;
    max-width: 95%;
  }
  .Activity_text__2Pmnr {
    font-size: 2vh;
  }

  .Activity_header__32xRr {
    margin-top: 10.7vh;
    margin-bottom: 4vh;
    font-size: 6.6vh;
    line-height: 7vh;
  }

  .Activity_image__2RLU1 {
    max-height: 48.5vh;
    max-width: 480px;
    width: auto;
    left: 5.2vh;
    top: 4.9vh;
  }
}

@media screen and (min-width: 1280px) {
  .Activity_text__2Pmnr {
    font-size: 1.8vh;
  }
}

.Tools_technologies__yf0FC {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: -4vh;
}

.Tools_image__fRWrx {
  max-width: 100%;
}

.Tools_header__11BVi {
  font-size: 40px;
  font-weight: 900;
  line-height: 73px;
  color: var(--tuna);
  letter-spacing: 1px;
}

.Tools_list__T1lMy li {
  margin-bottom: 15px;
  padding-left: 7px;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0;
}

.Tools_title__3VwHD {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.Tools_picture__1VPQ7 {
  position: relative;
}

@media screen and (min-width: 768px) {
  .Tools_block__3KW62 {
    width: 50%;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .Tools_technologies__yf0FC {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  .Tools_media__1L2ao,
  .Tools_content__3Ez72 {
    margin-top: -6vh;
    width: 50%;
  }

  .Tools_title__3VwHD {
    font-weight: 600;
    font-size: 2.7vh;
    margin-bottom: 0.5vh;
  }
  .Tools_list__T1lMy li {
    margin-bottom: 2vh;
    padding-left: 7px;
    font-size: 2.1vh;
    line-height: 3.7vh;
    margin-bottom: 0;
  }

  .Tools_reverse__21Qrr {
    flex-direction: column-reverse;
  }
  .Tools_image__fRWrx {
    max-height: 76vh;
    display: block;
    margin: 0 auto;
  }
  .Tools_technologies__yf0FC {
    margin-left: -1vh;
  }
  .Tools_picture__1VPQ7 {
    max-width: 622px;
    left: -33px;
    top: 105px;
  }

  .Tools_header__11BVi,
  .Tools_technologies__yf0FC {
    padding-left: 8vh;
  }

  .Tools_header__11BVi {
    margin-top: 16.7vh;
    margin-bottom: 5.3vh;
    margin-left: -1vh;
    font-size: 6.6vh;
    line-height: 7vh;
  }
}

.Learn_primaryHeader__23sHS {
  font-size: 40px;
  font-weight: 900;
  line-height: 73px;
  color: var(--tuna);
  letter-spacing: 1px;
}

.Learn_paragraph__2sTQY {
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 20px;
  word-break: break-word;
}

.Learn_learn__2-JJG {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 8px;
  border: solid 1px var(--red);
  color: var(--tuna);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.04px;
  text-align: center;
  padding: 12px 14px;
  outline: none;
  transition: 0.35s;
}

.Learn_linkText__1nJww {
  position: relative;
  top: 1px;
}

.Learn_media__3VNiA {
  position: relative;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
}

.Learn_content__1-HYq {
  order: 1;
}

.Learn_media__3VNiA {
  order: 0;
}

.Learn_desktopDots__oH3hR {
  display: none;
  pointer-events: none;
  right: 90px;
}

.Learn_mobileDots__25hO4 {
  position: absolute;
  pointer-events: none;
  left: 50%;
  margin-left: -80px;
}

.Learn_image__3TvCe {
  position: relative;
  z-index: 1;
  width: 90vw;
  max-width: 483px;
  margin-left: -15px;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .Learn_image__3TvCe {
    left: 50px;
    top: 50px;
  }

  .Learn_media__3VNiA {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1024px) {
  .Learn_primaryHeader__23sHS {
    margin-top: 11vh;
    margin-bottom: 4vh;
    font-size: 6.6vh;
    line-height: 7vh;
  }

  .Learn_paragraph__2sTQY {
    font-size: 2vh;
    line-height: 3.5vh;
    margin-bottom: 1.5vh;
    margin-right: 60px;
    max-width: 95%;
  }

  .Learn_learn__2-JJG {
    padding: 0;
    margin-top: 29px;
    padding: 12px 16px;
    font-size: 11px;
  }

  .Learn_content__1-HYq,
  .Learn_media__3VNiA {
    margin-top: 6vh;
  }
  .Learn_content__1-HYq {
    order: 0;
  }
  .Learn_media__3VNiA {
    order: 1;
    top: 7vh;
    left: 2vh;
    margin-bottom: 80px;
  }
  .Learn_content__1-HYq,
  .Learn_media__3VNiA {
    width: 50%;
  }
  .Learn_media__3VNiA {
    height: auto;
    margin-bottom: 0;
  }

  .Learn_mobileDots__25hO4 {
    display: none;
  }

  .Learn_desktopDots__oH3hR {
    display: block;
    position: absolute;
    top: -50px;
  }

  .Learn_image__3TvCe {
    max-width: 110%;
    width: auto;
    left: -60px;
    top: -10px;
  }
}

h1.TextHeader_root__2xWOY {
  color: var(--tuna);
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 1.35px;
  line-height: 48px;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 0;
}

h2.TextHeader_root__2xWOY {
  color: var(--tuna);
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  margin-top: 0;
}

.TextHeader_root__2xWOY.TextHeader_white__1lbuU {
  color: var(--white);
}

@media screen and (min-width: 1024px) {
  h1.TextHeader_root__2xWOY {
    color: var(--tuna);
    font-size: 6vh;
    line-height: 9vh;
    font-weight: 900;
  }

  h2.TextHeader_root__2xWOY {
    font-size: 3.5vh;
    line-height: 6vh;
  }
}

@media screen and (min-width: 1280px) {
  h1.TextHeader_root__2xWOY {
    font-size: 6.6vh;
    margin-bottom: 18px;
  }
  h2.TextHeader_root__2xWOY {
    font-size: 2.7vh;
    line-height: 4.5vh;
  }
}

.Button_root___vQ1s {
  border-radius: 8px;
  background-color: var(--jungle-green);
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 16px;
  text-align: center;
  padding: 12px 14px;
  border: none;
  outline: none;
  transition: 0.35s;
}

.Button_root___vQ1s:hover {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05), 0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.Button_root___vQ1s.Button_primary__2ER0R {
  background-color: transparent;
  border: none;
  color: var(--jelly-bean);
  border: 1px solid var(--jelly-bean);
  font-size: 18px;
}

.Button_root___vQ1s.Button_info__2tvgD {
  background-color: var(--jelly-bean);
  color: var(--white);
}

.Button_root___vQ1s:hover {
  cursor: pointer;
}

.Vacancies_root__1x0EL {
  background-color: var(--link-water);
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 90px;
}

.Vacancies_container__uHW3L {
  max-width: 1539px;
  margin: auto;
  position: relative;
}

.Vacancies_description__32y0j,
.Vacancies_action__3W2e2 {
  text-align: left;
}

.Vacancies_text__10ygH {
  max-width: 844px;
  margin: auto;
  margin-bottom: 60px;
}

.Vacancies_vacancies__3mApa {
  display: flex;
}

.Vacancies_vacancy__FraXa {
  background: var(--white);
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.15);
  color: var(--tuna);
  border-radius: 13px;
  height: 138px;
  padding: 22px 10px 20px;
  box-sizing: border-box;
  margin: 37px 0;
  outline: none;
  text-align: center;
  max-width: 345px;
}

.Vacancies_singleVacancy___6gDm {
  padding: 40px 0;
  margin-bottom: 80px;
}

.Vacancies_singleVacancy___6gDm .Vacancies_vacancy__FraXa {
  max-width: 336px;
  margin: 0 auto;
  position: relative;
}

.Vacancies_title__3dtAz {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 900;
  color: var(--tuna);
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.Vacancies_price__1Riwk {
  font-size: 20px;
  color: var(--tuna);
  letter-spacing: 0.07px;
  line-height: 16px;
  margin-bottom: 10px;
}

.Vacancies_price__1Riwk sup {
  color: var(--tuna);
  font-size: 11px;
  position: relative;
  top: -9px;
}

.slick-track {
  display: flex;
}

.Vacancies_slider__2RwHO {
  padding-bottom: 60px;
  text-align: center;
}

.Vacancies_slider__2RwHO button {
  text-transform: uppercase;
  min-width: 172px;
}

.Vacancies_details__hmAKQ {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

.Vacancies_dotsDesktop__2IKvB {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: none;
}

.slick-slide > div {
  padding: 0 23px;
}

@media screen and (min-width: 1024px) {
  .Vacancies_description__32y0j,
  .Vacancies_action__3W2e2 {
    text-align: center;
  }
  .Vacancies_singleVacancy___6gDm .Vacancies_vacancy__FraXa {
    min-width: 407px;
    top: 17px;
    left: 13px;
  }
  .Vacancies_root__1x0EL {
    padding: 90px 20px 0;
    min-height: 100vh;
  }
  .slick-track {
    display: block;
  }
  .Vacancies_dotsDesktop__2IKvB {
    display: block;
    max-width: 1190px;
    width: 100%;
  }
  .Vacancies_paragraph__2bJIO {
    margin: 47px auto 30px;
    max-width: 109vh;
    font-size: 2.2vh;
    line-height: 3.8vh;
  }
}

.Contacts_root__3U5L6 {
  min-height: 100vh;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80% 200px;
  overflow: hidden;
  background-color: var(--allports);
}

#root .Contacts_header__1NYy9 {
  color: var(--allports);
  margin-bottom: 17px;
}

.Contacts_container__xUmft {
  margin: auto;
  padding: 0 20px;
  margin-top: 120px;
}

.Contacts_info__R6Fl4 {
  background: var(--white);
  border-radius: 7px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  height: 244px;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
}

.Contacts_dotsMobile__288EO {
  position: absolute;
  top: -19px;
  left: -7px;
  pointer-events: none;
}

.Contacts_dotsDesktop__dRLw8 {
  display: none;
  position: absolute;
  top: -15px;
  left: -15px;
  pointer-events: none;
}

.Contacts_item__2A3h6 {
  display: flex;
  align-items: center;
  color: var(--allports);
  margin-bottom: 22px;
  font-size: 15px;
}

.Contacts_item__2A3h6 img {
  margin-right: 15px;
}

.Contacts_phoneNumber__3ifFg,
.Contacts_mailLink__1ZmvJ {
  text-decoration: none;
  color: var(--allports);
}

@media screen and (min-width: 1024px) {
  .Contacts_root__3U5L6 {
    background-position: -550px center;
  }
  .Contacts_info__R6Fl4 {
    background: var(--white);
    border-radius: 7px;
    margin-top: 152px;
    margin-left: 62px;
    padding: 66px 40px 0 81px;
    box-sizing: border-box;
    height: 398px;
    max-width: 567px;
  }

  .Contacts_container__xUmft {
    max-width: 1539px;
    padding: 0;
    display: flex;
    margin-top: 0;
  }

  .Contacts_item__2A3h6 {
    font-size: 16px;
  }

  .Contacts_item__2A3h6 img {
    margin-right: 25px;
  }

  .Contacts_dotsDesktop__dRLw8 {
    display: block;
  }

  .Contacts_dotsMobile__288EO {
    display: none;
  }

  #root .Contacts_header__1NYy9 {
    color: var(--allports);
    margin-bottom: 37px;
  }
}

@media screen and (min-width: 1280px) {
  .Contacts_root__3U5L6 {
    background-position: right top;
  }
}

.ReactModal__Overlay {
  z-index: 10;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  display: flex;
  justify-content: center;
}

.ReactModal__Content {
  transition: all 500ms ease-in-out;
  max-width: 1370px;
  -webkit-transform: translate3d(0, 150px, 0);
          transform: translate3d(0, 150px, 0);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--after-open {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ReactModal__Content--before-close {
  -webkit-transform: translate3d(0, 150px, 0);
          transform: translate3d(0, 150px, 0);
}

.Modal_modal__F_ggn {
  top: 85px;
  right: auto;
  left: auto;
  bottom: 0px;
  height: calc(100% - 85px);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: rgb(244, 248, 252);
  position: absolute;
}

.Modal_closeButton__BAMzw {
  background: transparent;
  border: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.Modal_closeIcon__3bX30 {
  width: 20px;
  height: 20px;
}

.Modal_inner__JC8r3 {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}

.Vacancies_vacancies__1JvNo {
  padding: 0 20px 20px 0;
}

.Vacancies_vacancy__duVe7 {
  box-sizing: border-box;
  outline: none;
  padding: 30px 34px;
  border-radius: 4px;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.15);
  margin-bottom: 28px;
  min-height: 79px;
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in 0s;
  background: var(--white);
  text-align: center;
}
.Vacancies_vacancy__duVe7:hover,
.Vacancies_active__xHRN_ {
  box-shadow: 1px 1px 17px 2px rgba(0, 0, 0, 0.06);
}
.Vacancies_vacancy__duVe7:last-child {
  margin-bottom: 0;
}

.Vacancies_title__1ByiS {
  color: var(--tuna);
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.4px;
}

.Vacancies_salary__2FMnH {
  color: var(--black);
  font-size: 14px;
  line-height: 16px;
}

.Vacancies_currency__kmEfN {
  margin-right: 5px;
}

@media screen and (max-width: 1024px) {
  .Vacancies_vacancies__1JvNo {
    display: none;
  }
}

.VacanciesDetail_root__1GTCq {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  padding: 45px 20px 20px 50px;
}

.VacanciesDetail_vacancy__3QDje {
  max-width: 1200px;
  margin: 0 50px 0 100px;
  overflow: auto;
}

.VacanciesDetail_title__1ieRG {
  font-size: 39px;
  color: var(--tuna);
}

.VacanciesDetail_salary__36prJ {
  font-weight: 600;
  font-size: 20px;
}

.VacanciesDetail_head__3Db9o {
  padding: 18px 20px 44px;
}

.VacanciesDetail_description__1jleD {
  padding: 20px;
  font-size: 2.2vh;
  line-height: 4vh;
}

.VacanciesDetail_button__2lSUE {
  margin-left: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.vacancy-description {
  margin-top: -40px;
  font-size: 20px;
  line-height: 30px;
}

.experience {
  margin-bottom: 10px;
}

.expectations {
  margin-bottom: 20px;
}

.employment {
  margin-bottom: 10px;
}

.requirements {
  padding-left: 25px;
}

.VacanciesDetail_link__3x9VM {
  background-color: var(--jelly-bean);
  color: var(--white);
  text-transform: uppercase;
  text-decoration: none;
  padding: 12px 14px;
  display: inline-block;
  font-size: 12px;
  border-radius: 8px;
}

@media screen and (max-width: 1024px) {
  .VacanciesDetail_vacancy__3QDje {
    margin: 0;
  }
  .VacanciesDetail_head__3Db9o,
  .VacanciesDetail_vacancy__3QDje {
    border: 0;
  }
}

.MobileMenu_root__1o3t5 {
  background-color: var(--jelly-bean);
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 20%;
  z-index: 20;
  transition: 1s;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  overflow-y: auto;
  padding: 0 0 30px 0;
}

.MobileMenu_menuOpened__24dfD.MobileMenu_root__1o3t5 {
  transition: 1s;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.MobileMenu_closeIcon__1POZl {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.MobileMenu_root__1o3t5 ul {
  margin: 0;
  list-style-type: none;
  margin-top: 90px;
}

.MobileMenu_root__1o3t5 ul li {
  margin-bottom: 30px;
}

.MobileMenu_root__1o3t5 ul li a {
  color: var(--white);
  text-decoration: none;
}

.MobileMenu_sendResume__2ce6- {
  border-radius: 8px;
  background-color: var(--jungle-green);
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 16px;
  text-align: center;
  border: none;
  margin-left: 40px;
  text-decoration: none;
  padding: 12px 14px;
}

.App_menuOpened__1pXNC main:before {
  content: '';
  background-color: #000;
  position: fixed;
  z-index: 15;
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

main > section {
  padding: 58px 0 0 0;
}

.App_root__T1Mkj {
  overflow: hidden;
}

