.root {
  background-color: var(--jelly-bean);
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 20%;
  z-index: 20;
  transition: 1s;
  transform: translateX(100%);
  overflow-y: auto;
  padding: 0 0 30px 0;
}

.menuOpened.root {
  transition: 1s;
  transform: translateX(0%);
}

.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.root ul {
  margin: 0;
  list-style-type: none;
  margin-top: 90px;
}

.root ul li {
  margin-bottom: 30px;
}

.root ul li a {
  color: var(--white);
  text-decoration: none;
}

.sendResume {
  border-radius: 8px;
  background-color: var(--jungle-green);
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 16px;
  text-align: center;
  border: none;
  margin-left: 40px;
  text-decoration: none;
  padding: 12px 14px;
}
