.root {
  background-color: var(--link-water);
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 90px;
}

.container {
  max-width: 1539px;
  margin: auto;
  position: relative;
}

.description,
.action {
  text-align: left;
}

.text {
  max-width: 844px;
  margin: auto;
  margin-bottom: 60px;
}

.vacancies {
  display: flex;
}

.vacancy {
  background: var(--white);
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.15);
  color: var(--tuna);
  border-radius: 13px;
  height: 138px;
  padding: 22px 10px 20px;
  box-sizing: border-box;
  margin: 37px 0;
  outline: none;
  text-align: center;
  max-width: 345px;
}

.singleVacancy {
  padding: 40px 0;
  margin-bottom: 80px;
}

.singleVacancy .vacancy {
  max-width: 336px;
  margin: 0 auto;
  position: relative;
}

.title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 900;
  color: var(--tuna);
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.price {
  font-size: 20px;
  color: var(--tuna);
  letter-spacing: 0.07px;
  line-height: 16px;
  margin-bottom: 10px;
}

.price sup {
  color: var(--tuna);
  font-size: 11px;
  position: relative;
  top: -9px;
}

:global(.slick-track) {
  display: flex;
}

.slider {
  padding-bottom: 60px;
  text-align: center;
}

.slider button {
  text-transform: uppercase;
  min-width: 172px;
}

.details {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

.dotsDesktop {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: none;
}

:global(.slick-slide > div) {
  padding: 0 23px;
}

@media screen and (min-width: 1024px) {
  .description,
  .action {
    text-align: center;
  }
  .singleVacancy .vacancy {
    min-width: 407px;
    top: 17px;
    left: 13px;
  }
  .root {
    padding: 90px 20px 0;
    min-height: 100vh;
  }
  :global(.slick-track) {
    display: block;
  }
  .dotsDesktop {
    display: block;
    max-width: 1190px;
    width: 100%;
  }
  .paragraph {
    margin: 47px auto 30px;
    max-width: 109vh;
    font-size: 2.2vh;
    line-height: 3.8vh;
  }
}
