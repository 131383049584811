.meetText {
  margin-bottom: 39px;
}

.paragraph {
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 20px;
  word-break: break-word;
}

.primaryHeader {
  font-size: 40px;
  font-weight: 900;
  line-height: 73px;
  color: var(--tuna);
  letter-spacing: 1px;
}

.secondaryHeader {
  font-size: 24px;
  font-weight: 600;
  line-height: 39px;
  color: var(--tuna);
}

.media {
  position: relative;
  left: 2vh;
  margin-bottom: 60px;
}

.content {
  order: 1;
}

.media {
  order: 0;
}

.desktopDots {
  position: absolute;
  max-width: 75vw;
}

.image {
  position: relative;
  z-index: 1;
  width: 68vw;
  max-width: 483px;
  top: 7vw;
  left: 7vw;
}

@media screen and (min-width: 768px) {
  .image {
    left: 50px;
    top: 50px;
  }
  .media {
    margin-bottom: 120px;
  }
}
@media screen and (min-width: 1024px) {
  .secondaryHeader {
    font-size: 2.8vh;
    line-height: 4.4vh;
  }

  .content,
  .media {
    margin-top: 6vh;
  }
  .content {
    order: 0;
  }
  .media {
    order: 1;
    top: 7vh;
    margin-bottom: 80px;
  }
  .desktopDots {
    position: absolute;
    max-height: 60vh;
    max-width: none;
  }
  .image {
    max-width: 100%;
    top: 5.5vh;
    left: 5.5vh;
    max-height: 49vh;
    width: auto;
  }
  .primaryHeader {
    margin-top: 11vh;
    margin-bottom: 4vh;
    font-size: 6.6vh;
    line-height: 7vh;
  }
  .secondaryHeader {
    margin-top: 5.5vh;
    margin-bottom: 0;
  }
  .paragraph {
    font-size: 2vh;
    line-height: 3.5vh;
    margin-bottom: 1.5vh;
    max-width: 95%;
  }
  .content,
  .media {
    width: 50%;
  }
  .media {
    height: auto;
    margin-bottom: 0;
  }
}
