.vacancies {
  padding: 0 20px 20px 0;
}

.vacancy {
  box-sizing: border-box;
  outline: none;
  padding: 30px 34px;
  border-radius: 4px;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.15);
  margin-bottom: 28px;
  min-height: 79px;
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in 0s;
  background: var(--white);
  text-align: center;
}
.vacancy:hover,
.active {
  box-shadow: 1px 1px 17px 2px rgba(0, 0, 0, 0.06);
}
.vacancy:last-child {
  margin-bottom: 0;
}

.title {
  color: var(--tuna);
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.4px;
}

.salary {
  color: var(--black);
  font-size: 14px;
  line-height: 16px;
}

.currency {
  margin-right: 5px;
}

@media screen and (max-width: 1024px) {
  .vacancies {
    display: none;
  }
}
